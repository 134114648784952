<template>
  <div class="container">
    <div class="row justify-content-center mt-3 mb-5">
      <div class="mb-5">
        <PageTitle :title="'Coaching invitation'"></PageTitle>

        <LoadingMessage v-if="isLoading"></LoadingMessage>

        <ErrorMessage v-if="error" :error="error"></ErrorMessage>

        <div v-if="invitation && coach" class="card">
          <div v-if="!invitationIsAccepted" class="card-body">
            <p class="card-text">
              {{ coachName }} would like to add you as a client.<br>
              Would you like to accept this request?
            </p>
            <SubmitButton
              @click="acceptInvitation"
              :text="'Yes, let\'s do it'"
              :textBusy="'Please wait...'"
              :isLoading="isSubmitting"
              :disabled="isSubmitting"
              class="btn btn-primary"
            ></SubmitButton>
            <router-link v-if="!isSubmitting" to="/" class="btn btn-link">Not now</router-link>
          </div>
          <div v-else class="card-body">
            <p class="card-text">
              Done! You are now added to {{ coach.firstName }}'s client list.
            </p>
            <router-link to="/" class="btn btn-primary">Go to dashboard</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { httpGet, httpPost } from '@/core/http';

export default {
  name: 'CoachingInvitation',
  components: {
    PageTitle: () => import('@/components/PageTitle'),
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    invitationCode() {
      return this.$route.params.invitationCode;
    },
    coachName() {
      const { firstName, lastName } = this.coach;
      return [firstName, lastName].join(' ');
    },
  },
  methods: {
    async fetchInvitation() {
      this.isLoading = true;
      this.error = null;
      try {
        const res = await httpGet(`/invitation/${this.invitationCode}`);
        const { invitation } = res.data;

        if (invitation.recipientEmail !== this.authUser.email) {
          throw Error('Invalid invitation');
        }

        this.invitation = invitation;
        this.coach = invitation.coach;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    async acceptInvitation() {
      this.isSubmitting = true;
      this.error = null;
      try {
        await httpPost(`/invitation/${this.invitationCode}/accept`);
        this.invitationIsAccepted = true;
      } catch (err) {
        this.error = err;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      error: null,
      coach: null,
      invitation: null,
      invitationIsAccepted: null,
    };
  },
  mounted() {
    this.fetchInvitation();
  },
};
</script>
